import { Fancybox } from "../resources/plugins/@fancyapps/ui";

// Подключение свайпера
const girlsSlider = new Swiper('#girls-slider', {
  slidesPerView: 'auto',
  spaceBetween: 24,
  navigation: {
    nextEl: '.mainSlider__navigation .js--swiperButtonNext',
    prevEl: '.mainSlider__navigation .js--swiperButtonPrev',
  },
  pagination: {
    el: '.mainSlider__navigation .js--swiperPagination',
    clickable: true,
  },
});

const cardPhotoSliders = document.querySelectorAll('.card__photoSlider');
cardPhotoSliders.forEach((el, ind) => {
  const cardPhotoSlider = new Swiper(`#card-photoSlider-${ind}`, {
    slidesPerView: 'auto',
    spaceBetween: 24,
    navigation: {
      nextEl: '.card__photoSliderNavigation .js--swiperButtonNext',
      prevEl: '.card__photoSliderNavigation .js--swiperButtonPrev',
    },
    pagination: {
      el: '.card__photoSliderNavigation .js--swiperPagination',
      clickable: true,
    },
  });
});

// Открытие фильтров
const sortOpens = document.querySelectorAll('.js--sortOpen');
sortOpens.forEach((el) => {
  el.addEventListener('click', (event) => {
    const clickedElement = event.currentTarget;

    sortOpens.forEach((el) => {
      if (el !== clickedElement) {
        el.classList.remove('form__input--opened');
      }
    });

    clickedElement.classList.toggle('form__input--opened');
  });
})

const filterOpen = document.querySelector('.js--showFilter');
const fixedFilter = document.querySelector('.js--fixedFilter');
const fixedFilterClose = document.querySelector('.js--fixedFilterClose');
if (filterOpen) {
  filterOpen.addEventListener('click', (event) => {
    fixedFilter.classList.toggle('sort__filter--visible');
  });

  fixedFilterClose.addEventListener('click', (event) => {
    fixedFilter.classList.toggle('sort__filter--visible');
  });
}

// Открытие фильтров в header
const sortMenuOpens = document.querySelectorAll('.js--sortMenuOpen');
sortMenuOpens.forEach((el) => {
  el.addEventListener('click', (event) => {
    const clickedElement = event.currentTarget;

    sortMenuOpens.forEach((el) => {
      if (el !== clickedElement) {
        el.classList.remove('sort__btn--opened');
      }
    });

    clickedElement.classList.toggle('sort__btn--opened');
  });
})

// Закрытие фильтра
const filterCloses = document.querySelectorAll('.js--filterClose');
const filters = document.querySelectorAll('.js--filter');

if (filterCloses.length > 0) {
  filterCloses.forEach((el) => {
    el.addEventListener('click', (event) => {
      filters.forEach((el) => {
        el.classList.toggle('sort__btn--opened');
      });
    });
  })
}

// Fancybox
Fancybox.bind('[data-fancybox="gallery"]', {
  hideScrollbar: false,
});
Fancybox.bind('[data-fancybox="cardGallery"]', {
  hideScrollbar: false,
});
Fancybox.bind('[data-fancybox="modal"]', {
  hideScrollbar: false,
});

// More button
const moreBtns = document.querySelectorAll('.js--moreBtn');
if (moreBtns.length > 0) {
  moreBtns.forEach((el) => {
    el.addEventListener('click', (event) => {
      const moreBtn = event.currentTarget;
      const listToExpand = moreBtn.previousElementSibling;
      moreBtn.textContent = moreBtn.textContent === '+ View more' ? '- View less' : '+ View more';

      listToExpand.classList.toggle('sort__list--hidden');
    });
  })
}

// Tabs
tabs('services','tabs__panel','tabs__btn');
tabs('card','card__tabsPanel','card__tabsBtn');
// tabsAlternative('card');

// Accordion
toggleAccordion('faq');
